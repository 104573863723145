import {
  BackLink,
  BackLinkContainer,
} from "@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles"
import { Hyperlink, KpButton } from "@elements/index"
import { MarketplaceListingService } from "@services/MarketplaceListingService"
import { AuthFormLabel } from "@styles/Global.forms"
import { H3 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { ButtonTypes, S3BucketBaseUrl } from "@utils/Constant"
import { ListingDetailsType } from "@utils/ListingTypes"
import React, { Fragment } from "react"
import { Container, Form, Grid, Icon, Responsive } from "semantic-ui-react"
import { Formik } from "formik"
import * as Yup from "yup"
import {
  AskPricingAmount,
  EnquiryConfirmationContainer,
  ItemDetailsContactSection,
  ItemDetailsContactTitle,
  ItemDetailsContentArea,
  ItemDetailsContentAttributeName,
  ItemDetailsContentAttributes,
  ItemDetailsContentAttributeSection,
  ItemDetailsContentAttributeValue,
  ItemDetailsContentDescription,
  ItemDetailsContentPricing,
  ItemDetailsContentTitle,
  ListingDetailsBannerContainer,
  ListingItemDetailsImageColumn,
  ListingItemPhotosContainer,
  MobileListingDetailsBannerContainer,
  PhotoThumbnail,
  PhotoThumbnailList,
  PhotoViewer,
  SelectedPhotoContainer,
} from "./ListingDetailsBanner.styles"
import ListingEnquiryForm from "./ListingEnquiryForm"
import { Link } from "gatsby"

interface ResponsiveListingDetailsBannerProps {
  bgLeftColor: string | string[]
  children: object | string
  paddingTop: number
  paddingBottom: number
  mobilePaddingTop: number
  mobilePaddingBottom: number
}
const ResponsiveListingDetailsBanner = ({
  bgLeftColor,
  children,
  paddingTop,
  paddingBottom,
  mobilePaddingTop,
  mobilePaddingBottom,
}: ResponsiveListingDetailsBannerProps) => {
  return (
    <Fragment>
      {/* desktop version */}
      <Responsive minWidth={theme.responsive.desktop.minWidth}>
        <ListingDetailsBannerContainer
          basic
          bgLeftColor={bgLeftColor}
          paddingTop={paddingTop}
          paddingBottom={paddingBottom}
        >
          <Container>{children}</Container>
        </ListingDetailsBannerContainer>
      </Responsive>
      {/* mobile and tablet version  */}
      <Responsive maxWidth={theme.responsive.tablet.maxWidth}>
        <MobileListingDetailsBannerContainer
          basic
          paddingBottom={mobilePaddingBottom}
          mobilePaddingTop={mobilePaddingTop}
        >
          <Container>{children}</Container>
        </MobileListingDetailsBannerContainer>
      </Responsive>
    </Fragment>
  )
}

type ListingDetailsBannerProps = {
  apiUrl: string
  listing: ListingDetailsType
}

const isDefaultImage = (url: string) => {
  return url.startsWith("https")
}

const ListingPhotosViewer = (props: ListingDetailsBannerProps) => {
  const [selectedPhoto, setSelectedPhoto] = React.useState(
    props.listing.imageUrls ? props.listing.imageUrls[0] : ""
  )
  const PhotoUrl = isDefaultImage(selectedPhoto)
    ? selectedPhoto
    : `${S3BucketBaseUrl}/public/${selectedPhoto}`

  return (
    <ListingItemPhotosContainer>
      <SelectedPhotoContainer>
        <PhotoViewer src={PhotoUrl} alt={props.listing.combination}/>
      </SelectedPhotoContainer>
      <PhotoThumbnailList>
        {props.listing.imageUrls &&
          props.listing.imageUrls.map(imageUrl => {
            const thumbnailPhoto = isDefaultImage(imageUrl)
              ? imageUrl
              : `${S3BucketBaseUrl}/public/${imageUrl}`
            return (
              <PhotoThumbnail
                src={thumbnailPhoto}
                alt={props.listing.combination}
                onClick={() => setSelectedPhoto(imageUrl)}
              />
            )
          })}
      </PhotoThumbnailList>
    </ListingItemPhotosContainer>
  )
}

const ListingDetailsBanner = (props: ListingDetailsBannerProps) => {
  return (
    <ResponsiveListingDetailsBanner
      bgLeftColor={theme.brand.colors.white}
      paddingTop={40}
      paddingBottom={40}
      mobilePaddingTop={0}
      mobilePaddingBottom={0}
    >
      <Grid columns={2}>
        <ListingItemDetailsImageColumn
          mobile={16}
          tablet={16}
          computer={8}
          largeScreen={8}
          widescreen={8}
        >
          <BackLinkContainer>
            <Hyperlink url="/marketplace/">
              <BackLink>
                <Icon name="chevron left" size="large" /> BACK TO ALL LISTINGS
              </BackLink>
            </Hyperlink>
          </BackLinkContainer>
          <ListingPhotosViewer {...props} />
        </ListingItemDetailsImageColumn>
        <Grid.Column
          mobile={16}
          tablet={16}
          computer={8}
          largeScreen={8}
          widescreen={8}
        >
          <ItemDetailsContentArea>
            <ItemDetailsContentTitle>
              <H3 textAlign="left">{props.listing.combination}</H3>
            </ItemDetailsContentTitle>
            <ItemDetailsContentPricing>
              <AskPricingAmount>${props.listing.price}</AskPricingAmount>
            </ItemDetailsContentPricing>
            <ItemDetailsContentDescription>
              {props.listing.description}
            </ItemDetailsContentDescription>
            <ItemDetailsContentAttributes>
              <ItemDetailsContentAttributeSection>
                <ItemDetailsContentAttributeName>
                  Design
                </ItemDetailsContentAttributeName>
                <ItemDetailsContentAttributeValue>
                  {props.listing.plateDesignName}
                </ItemDetailsContentAttributeValue>
              </ItemDetailsContentAttributeSection>
              <ItemDetailsContentAttributeSection>
                <ItemDetailsContentAttributeName>
                  Manufactured
                </ItemDetailsContentAttributeName>
                <ItemDetailsContentAttributeValue>
                  {props.listing.isManufactured ? "Yes" : "No"}
                </ItemDetailsContentAttributeValue>
              </ItemDetailsContentAttributeSection>
              <ItemDetailsContentAttributeSection>
                <ItemDetailsContentAttributeName>
                  Condition
                </ItemDetailsContentAttributeName>
                <ItemDetailsContentAttributeValue>
                  {props.listing.condition}
                </ItemDetailsContentAttributeValue>
              </ItemDetailsContentAttributeSection>
            </ItemDetailsContentAttributes>
            <ItemDetailsContactSection>
              <ItemDetailsContactTitle>Contact Seller</ItemDetailsContactTitle>
              <ListingEnquiryForm
                listingId={props.listing.listingId}
                apiUrl={props.apiUrl}
              ></ListingEnquiryForm>
            </ItemDetailsContactSection>
            <Link to={`/report-listing?listingId=${props.listing.listingId}`}>
              Report Listing
            </Link>
          </ItemDetailsContentArea>
        </Grid.Column>
      </Grid>
    </ResponsiveListingDetailsBanner>
  )
}

export default ListingDetailsBanner
