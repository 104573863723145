import { ListingDetailsType } from "@utils/ListingTypes"
import React from "react"
import {Field, Formik}  from 'formik';
import * as Yup from 'yup';
import { Checkbox, Form, Grid } from "semantic-ui-react";
import { KpFormField, KpFormInputErrorMessage } from "@styles/Global.forms";
import KpButton from "@elements/KpButton/KpButton";
import { Paragraph1 } from "@styles/Global.styles";
import theme from "@styles/theme";
import { KiwiVipSignupCheckbox, ListingEnquiryFormColumn } from "./ListingDetailsBanner.styles";
import { Auth } from "aws-amplify";
import { MarketplaceListingService } from "@services/MarketplaceListingService";
import { SubscriptionService } from "@services/SubscriptionService";
import { TermsConditionsCheckbox } from "@components/MakePayment/MakePayment.styles";
import { values } from "lodash";
import { htmlEncode } from "@utils/Helpers";
import { EmailRegex, NameRegex } from "@utils/Constant";


type ListingEnquiryFormProps = {
  listingId: number
  apiUrl: string
}

const ListingEnquiryForm = (props: ListingEnquiryFormProps) => {

  const formSettings = {
    intialStatus: {
      isSubmitting: false,
      isSubmitted: false
    },
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      message: '',
      kiwiVipSignup: false
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
          .required('First Name is required')
          .matches(NameRegex, "Enter valid characters"),
      lastName: Yup.string()
          .required('Last Name is required')
          .matches(NameRegex, "Enter valid characters"),
      email: Yup.string()
          .required('Email is required')
          .matches(EmailRegex ,'Must enter a valid email address'),
      message: Yup.string()
          .required('Message is required')
    })
  };

  const submitForm = (values: any, actions: any) => {
      actions.setStatus({
          isSubmitting: true
      });

      MarketplaceListingService.sendListingEnquiry(props.apiUrl, props.listingId, `${values.firstName} ${values.lastName}`, values.email, htmlEncode(values.message)).then((data: any) => {
        if(data.data.Success){
            if(values.kiwiVipSignup){
                SubscriptionService.subscribeUserRequest(
                    props.apiUrl,
                    values,
                    'Marketplace Enquiry',
                    {
                        campaignName: 'KiwiVIP'
                    }, false).then((data: any) => {
                    actions.setStatus({
                        isSubmitting: false,
                        isSubmitted: true
                    });
                });
            }
            else {
                actions.setStatus({
                    isSubmitting: false,
                    isSubmitted: true
                });
            }
        }
      })
  }

  return (
    <Formik initialValues={formSettings.initialValues} validationSchema={formSettings.validationSchema} initialStatus={formSettings.intialStatus} onSubmit={(values: any, actions: any) => submitForm(values, actions)}>
        { props => (
            <Form onSubmit={props.handleSubmit}>
                <Grid columns={16}>
                    <Grid.Row>
                        {
                            !props.status.isSubmitted && (
                                <Grid.Column mobile={16} tablet={16} computer={14}>
                                    <Grid width={14}>
                                        <ListingEnquiryFormColumn mobile={16} tablet={8} computer={8}>
                                            <Form.Group>
                                                <KpFormField required error={props.errors.firstName && props.touched.firstName}>
                                                    <label>First Name</label>
                                                    {
                                                        props.errors.firstName && props.touched.firstName && (
                                                            <KpFormInputErrorMessage>
                                                                {props.errors.firstName}
                                                            </KpFormInputErrorMessage>
                                                        )
                                                    }
                                                    <input
                                                        id='firstName'
                                                        name='firstName'
                                                        data-private="lipsum"
                                                        type='text'
                                                        aria-label='FirstName'
                                                        disabled={props.status.isSubmitting}
                                                        placeholder='First Name'
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.firstName}>
                                                    </input>
                                                </KpFormField>
                                            </Form.Group>
                                        </ListingEnquiryFormColumn>
                                        <ListingEnquiryFormColumn mobile={16} tablet={8} computer={8}>
                                            <Form.Group>
                                                <KpFormField required error={props.errors.lastName && props.touched.lastName}>
                                                    <label>Last Name</label>
                                                    {
                                                        props.errors.lastName && props.touched.lastName && (
                                                            <KpFormInputErrorMessage>
                                                                {props.errors.lastName}
                                                            </KpFormInputErrorMessage>
                                                        )
                                                    }
                                                    <input
                                                        id='lastName'
                                                        name='lastName'
                                                        data-private="lipsum"
                                                        type='text'
                                                        aria-label='LastName'
                                                        disabled={props.status.isSubmitting}
                                                        placeholder='Last Name'
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.lastName}>
                                                    </input>
                                                </KpFormField>
                                            </Form.Group>
                                        </ListingEnquiryFormColumn>
                                        <ListingEnquiryFormColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Group>
                                                <KpFormField required error={props.errors.lastName && props.touched.email}>
                                                    <label>Email</label>
                                                    {
                                                        props.errors.email && props.touched.email && (
                                                            <KpFormInputErrorMessage>
                                                                {props.errors.email}
                                                            </KpFormInputErrorMessage>
                                                        )
                                                    }
                                                    <input
                                                        id='email'
                                                        name='email'
                                                        data-private="lipsum"
                                                        aria-label='Email Address'
                                                        type='text'
                                                        disabled={props.status.isSubmitting}
                                                        placeholder='Email'
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.email}>
                                                    </input>
                                                </KpFormField>
                                            </Form.Group>
                                        </ListingEnquiryFormColumn>
                                        <ListingEnquiryFormColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Group>
                                                <KpFormField required error={props.errors.message && props.touched.message}>
                                                    <label>Message</label>
                                                    {
                                                        props.errors.message && props.touched.message && (
                                                            <KpFormInputErrorMessage>
                                                                {props.errors.message}
                                                            </KpFormInputErrorMessage>
                                                        )
                                                    }
                                                    <textarea
                                                        id='message'
                                                        name='message'
                                                        aria-label="Message"
                                                        disabled={props.status.isSubmitting}
                                                        placeholder='Message'
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.message}>
                                                    </textarea>
                                                </KpFormField>
                                            </Form.Group>
                                        </ListingEnquiryFormColumn>
                                        <ListingEnquiryFormColumn mobile={16} tablet={16} computer={16}>
                                            <Form.Group>
                                                <KpFormField>
                                                <KiwiVipSignupCheckbox onChange={(el: any, data: any) => {
                                                    props.setFieldValue(
                                                    "kiwiVipSignup",
                                                    data.checked
                                                    );
                                                }} checked={props.values.kiwiVipSignup} label='Sign up to KiwiVIP newsletter'/>
                                                </KpFormField>
                                            </Form.Group>
                                        </ListingEnquiryFormColumn>
                                        <Grid.Column mobile={16} tablet={16} computer={16}>
                                            <KpButton id="cub-send" type='submit' loading={props.status.isSubmitting} buttonType='primary' color={theme.brand.colors.blue}>SEND MESSAGE</KpButton>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                            )
                        }
                        {
                            props.status.isSubmitted && (
                                <Grid.Column verticalAlign='middle' width={16}>
                                    <Paragraph1 color={theme.brand.colors.black}>
                                        Your enquiry has been successfully sent to the listing owner.
                                    </Paragraph1>
                                </Grid.Column>
                            )
                        }
                    </Grid.Row>
                </Grid>
            </Form>
        )}
    </Formik>
  )
}

export default ListingEnquiryForm
